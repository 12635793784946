.extendedRadio {
  position: relative;
  background-color: #ffffff;
  border: solid 0.0625rem #a3a7ba;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.extendedRadio fieldset {
  left: 1.3rem;
  top: 1rem;
  position: absolute;
  margin: 0 !important;
}
.extendedRadio fieldset > label {
  font-size: 0;
}
.extendedRadio.checked {
  background: linear-gradient(222.84deg, rgba(37, 165, 132, 0.15) 0%, rgba(0, 169, 165, 0.15) 100%);
  border-color: #25a584;
}
.extendedRadio.checked p {
  color: #45baad;
}
.extendedRadio .content {
  width: 100%;
  padding: 1rem 1.5rem 1rem 3rem;
  margin: 0;
  cursor: pointer;
}
