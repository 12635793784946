div.otherAmount {
  margin: 0;
  position: relative;
  width: 90%;
}
div.otherAmount label {
  font-weight: 500;
  font-size: 1rem;
  color: #41444f;
}
div.otherAmount input {
  font-weight: 500;
  padding-left: 2rem;
}
div.otherAmount:before {
  content: "$";
  position: absolute;
  top: 2.05rem;
  left: 0.8rem;
  font-weight: bold;
  font-size: 1.375rem;
  color: #a3a7ba;
}

div.confirmHIPAA label {
  font-size: 0.9375rem;
  color: #41444f;
}

div.cardInput {
  position: relative;
}
div.cardInput:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 2.2rem;
  left: 0.5rem;
  width: 2rem;
  height: 1.5rem;
  background: transparent no-repeat center;
}
div.cardInput:before {
  background-image: url("../../assets/images/icons/card-placeholder.svg");
}
div.cardInput input {
  padding-left: 3rem;
}
div.cardInput.MasterCard::before {
  background-image: url("../../assets/images/icons/green-mastercard.svg");
}
div.cardInput.Visa::before {
  background-image: url("../../assets/images/icons/green-visa.svg");
}
div.cardInput.AmericanExpress::before {
  background-image: url("../../assets/images/icons/green-amex.svg");
}
div.cardInput.Discover::before {
  background-image: url("../../assets/images/icons/green-discover.svg");
}

div.disabledMask input {
  background-color: rgba(163, 167, 186, 0.15);
  pointer-events: none;
}

div.saveCheckbox {
  margin: 0;
  padding-right: 1rem;
}
@media (max-width: 767.98px) {
  div.saveCheckbox {
    width: 100%;
    padding-right: 0;
    text-align: left;
  }
}

div.repaymentAccepted {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin: 0;
}
@media (max-width: 767.98px) {
  div.repaymentAccepted {
    margin-bottom: 0.5rem;
  }
}

div.communication {
  margin: 0;
}
div.communication label {
  font-weight: 500;
}

div.ssnMask label {
  display: block;
}
div.ssnMask sup {
  position: relative;
  top: 0;
  font-weight: 900;
  font-size: 0.8125rem;
  margin: 0.3rem -0.1rem 0 0.25rem;
}

i.infoButton {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.25rem;
  background: transparent url(./../../assets/images/icons/info.svg) no-repeat;
  background-size: contain;
  position: relative;
  top: 0.4rem;
  left: -0.4rem;
}
