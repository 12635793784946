.aside {
  background-color: #ffffff;
}
.aside .navItem {
  min-height: 3rem;
}
.aside .navItem a {
  padding: 1rem 1rem 1rem 3.5rem;
  font-size: 0.875rem;
  color: #41444f;
  font-weight: 500;
  line-height: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.aside .navItem a.icon {
  position: relative;
}
.aside .navItem a.icon::before {
  content: "";
  background: no-repeat center;
  position: absolute;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  left: 1.25rem;
}
.aside .navItem a.personal::before {
  background-image: url("./../../../../assets/images/icons/personal-info.svg");
}
.aside .navItem a.contact::before {
  background-image: url("./../../../../assets/images/icons/contact.svg");
}
.aside .navItem a.address::before {
  background-image: url("./../../../../assets/images/icons/address.svg");
}
.aside .navItem a.employer::before {
  background-image: url("./../../../../assets/images/icons/employer.svg");
}
.aside .navItem a.statement::before {
  background-image: url("./../../../../assets/images/icons/statement.svg");
}
.aside .navItem a.language::before {
  background-image: url("./../../../../assets/images/icons/language.svg");
}
.aside .navItem a.blocked::before {
  background-image: url("./../../../../assets/images/icons/blocked.svg");
}
.aside .navItem a.active {
  cursor: pointer;
  background-color: rgba(37, 165, 132, 0.1);
  color: #25a584;
}
.aside .navItem a.active.personal::before {
  background-image: url("./../../../../assets/images/icons/personal-info-green.svg");
}
.aside .navItem a.active.contact::before {
  background-image: url("./../../../../assets/images/icons/contact-green.svg");
}
.aside .navItem a.active.address::before {
  background-image: url("./../../../../assets/images/icons/address-green.svg");
}
.aside .navItem a.active.employer::before {
  background-image: url("./../../../../assets/images/icons/employer-green.svg");
}
.aside .navItem a.active.statement::before {
  background-image: url("./../../../../assets/images/icons/statement-green.svg");
}
.aside .navItem a.active.language::before {
  background-image: url("./../../../../assets/images/icons/language-green.svg");
}
.aside .navItem a.active.blocked::before {
  background-image: url("./../../../../assets/images/icons/blocked-green.svg");
}
