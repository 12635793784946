html,
body,
.fill {
  background: #ffffff;
  height: 100%;
}
@media (max-width: 767.98px) {
  html.row,
  body.row,
  .fill.row {
    display: block;
  }
}

.logoWrapper {
  background: #ffffff url("../../../assets/images/background.jpg") no-repeat left center;
  background-size: cover;
}
@media (max-width: 767.98px) {
  .logoWrapper {
    background-image: none;
  }
}
.logoWrapper .pageLogo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30%;
  margin-left: 8%;
  height: 80vh;
}
@media (max-width: 767.98px) {
  .logoWrapper .pageLogo {
    padding-top: 1rem;
    margin-left: 5%;
    justify-content: flex-start;
    flex-direction: initial;
    justify-content: initial;
    margin-top: initial;
    height: auto;
  }
}
.logoWrapper .pageLogo .pageLogoContainer {
  display: flex;
  height: auto;
  align-items: center;
}
.logoWrapper .phoneNumber {
  position: absolute;
  margin-left: 5%;
  bottom: 3%;
  font-size: 12px;
  color: #ffffff;
}
.logoWrapper .questions {
  display: block;
  color: white;
  font-size: 80%;
}
@media (max-width: 767.98px) {
  .logoWrapper .questions {
    display: none;
  }
}

@media (min-width: 768px) {
  .bigScreenLogo {
    display: block;
  }
  .smallScreenLogo {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .bigScreenLogo {
    display: none;
  }
  .smallScreenLogo {
    display: block;
  }
}
.mainContainer {
  position: relative;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .mainContainer {
    align-items: flex-start;
    padding: 2rem 0;
    width: 100%;
    height: auto;
  }
}
.mainContainer .row {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 3rem;
}
@media (max-width: 767.98px) {
  .mainContainer .row {
    padding-top: 0;
  }
}
