div.signUp {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding-top: 3rem;
}
@media (max-width: 767.98px) {
  div.signUp {
    padding-top: 0;
  }
}
div.signUp .heading {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}
div.signUp .subHeading {
  font-size: 1.125rem;
  font-weight: 300;
  padding-bottom: 0;
}
div.signUp h1 strong {
  margin-right: 0.5rem;
}
div.signUp h1 .secondary {
  font-weight: normal;
  display: inline-block;
  font-size: 90%;
}
div.signUp .datePickerWrapper {
  position: relative;
  z-index: 200;
}
div.signUp .datePickerWrapper:before {
  content: url("../../../assets/images/icons/calendar.svg");
  position: absolute;
  left: 0.625rem;
  top: 2.25rem;
  z-index: 4;
}
div.signUp .datePickerWrapper:after {
  content: "";
  position: absolute;
  top: 2.75rem;
  right: 0.625rem;
  width: 0.75rem;
  height: 0.46875rem;
  background: url("../../../assets/images/icons/more.svg") no-repeat center;
  background-size: contain;
}
div.signUp .datePickerWrapper.open:after {
  transform: rotate(180deg);
}
div.signUp .datePickerWrapper input {
  padding-left: 2.25rem;
}
div.signUp .datePickerWrapper .datePicker {
  position: absolute;
  top: 4.375rem;
  left: 0;
  border-radius: 0.125rem;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(158, 173, 189, 0.5);
  left: auto;
  right: 0;
  top: auto;
  bottom: 2.8125rem;
}
div.signUp .datePickerWrapper .datePicker.invalid {
  bottom: 4rem;
}
@media (max-width: 767.98px) {
  div.signUp .datePickerWrapper .datePicker {
    width: 100%;
    left: 0;
    right: auto;
  }
}
div.signUp .info:before {
  content: "";
  background: transparent url("../../../assets/images/icons/info.svg") no-repeat center;
  width: 2.5rem;
  height: 2.5rem;
  background-size: contain;
  position: absolute;
  left: -0.6rem;
  top: -0.6rem;
}
div.signUp .validationTypeSwitcher {
  width: 100%;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
div.signUp .validationTypeSwitcher button {
  width: 50%;
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: 500;
  padding: 0.6rem 2rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
div.signUp .validationTypeSwitcher button.active {
  cursor: pointer;
  background-color: rgba(37, 165, 132, 0.1);
  color: #25a584;
}
div.signUp .accountNumberInfo {
  position: absolute;
  z-index: 100;
  background: white;
  padding: 1.5rem;
  border: thin solid #ddd;
}
div.signUp .infoIcon {
  position: relative;
  top: -0.125rem;
  left: 0.0625rem;
  margin: 0 0.1rem 0 0.3rem;
}
div.signUp .infoIcon.infoLegend {
  margin: 0 0.3rem 0 0;
}
div.signUp .ssnMessage {
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  font-size: 0.75rem;
  opacity: 0.6;
  border-top: solid 1px #a3a7ba;
}
div.signUp .ssnMessage p {
  margin: 0;
  padding-bottom: 0.5rem;
}
div.signUp .ssnMessage p:last-child {
  margin: 0;
  padding: 0;
}

div.sentEmail,
div.registered {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
}

.forgotPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
}
.forgotPassword .heading {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}
.forgotPassword .subHeading {
  font-size: 1.125rem;
  font-weight: 300;
  padding-bottom: 0;
}
.forgotPassword .form {
  margin-top: 1.25rem;
}
.forgotPassword .form button {
  margin-top: 1.25rem;
}

.registration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100%;
}

.link {
  display: block;
  position: relative;
  font-size: 0.8125rem;
  margin-bottom: 3.125rem;
  padding-left: 1.5625rem;
}
.link:before {
  content: url("../../../assets/images/icons/arrow-left.svg");
  position: absolute;
  left: 0;
  top: 0.09375rem;
}
.link.align {
  margin-left: 5rem;
}
@media (max-width: 575.98px) {
  .link.align {
    margin-left: 0;
  }
}

.userNameHelpers li {
  color: #41444F;
  font-size: 15px;
}

.oktaLoginPassesRule {
  list-style-image: url("../../../assets/images/icons/check.svg");
}
