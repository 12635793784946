.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  opacity: 1;
}
.icon.info {
  background: transparent url(./../../assets/images/icons/info.svg) no-repeat center;
}

.pointer {
  cursor: pointer;
}
