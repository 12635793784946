.terms {
  font-size: 0.9375rem;
  line-height: 1.55;
}
.terms h2 {
  font-size: 0.9375rem;
}
.terms p {
  margin-bottom: 1rem;
}
.terms ul {
  margin-top: 1rem;
  padding-left: 1rem;
}
.terms ul li {
  list-style: none;
  margin-bottom: 1rem;
}

.address {
  font-size: 0.9375rem;
  padding: 0;
  text-transform: uppercase;
}
.address p:last-child {
  margin: 0;
}
