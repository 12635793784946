.shadowBox {
  padding: 2rem;
  margin: 1.5rem 0;
  box-shadow: 0 0.125rem 0.6875rem 0 rgba(163, 167, 186, 0.49);
  background: #ffffff;
}

.small {
  padding: 0;
  margin: 1rem 0;
}
@media (max-width: 991.98px) {
  .small {
    margin: 0.5rem 0;
  }
}

.active {
  margin-left: -1rem;
  margin-right: -1rem;
}
