button.icon {
  border: none;
  background: #ffffff no-repeat center;
}
button.icon.print {
  background-image: url("./../../assets/images/icons/print.svg");
}
button.icon.download {
  background-image: url("./../../assets/images/icons/download.svg");
}
