.title {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  background-color: rgba(69, 186, 173, 0.16);
  padding: 0.3125rem 0.5rem;
}
.title.modal-table {
  padding-left: 1.5rem;
}

.expandedTableTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  position: relative;
}
.expandedTableTitle:before {
  position: absolute;
  height: 0.938rem;
  width: 0.438rem;
  content: "";
  background: transparent url("../../assets/images/icons/angle-left-black.svg") no-repeat center;
  background-size: contain;
  transform: rotate(-90deg);
  left: 6%;
  top: 10%;
}
.expandedTableTitle.expand:before {
  position: absolute;
  height: 0.938rem;
  width: 0.438rem;
  content: "";
  background: transparent url("../../assets/images/icons/angle-left-black.svg") no-repeat center;
  background-size: contain;
  transform: rotate(90deg);
  left: 6%;
  top: 10%;
}
.expandedTableTitle.modal-table {
  padding-left: 3.5rem;
}

.sortedHead {
  cursor: pointer;
}

.sort {
  display: inline-block;
  position: relative;
  top: 0.0625rem;
  width: 0.6875rem;
  height: 0.6875rem;
  background-size: cover;
  cursor: pointer;
}
.sort.desc {
  top: 0.29rem;
}
.sort.asc {
  top: 0.01rem;
}

table tbody td a {
  text-decoration: underline;
}
