.status {
  position: relative;
  padding-left: 5rem;
}
@media (max-width: 575.98px) {
  .status {
    padding-left: 0;
  }
}
.status:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.125rem;
  box-shadow: 0 0.1875rem 0.625rem 0 rgba(132, 143, 140, 0.8);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
  width: 3.5rem;
  height: 3.5rem;
}
@media (max-width: 575.98px) {
  .status:before {
    display: none;
  }
}
.status.success::before {
  background-image: url("../../assets/images/icons/check.svg");
}
.status.sent-email::before {
  background-image: url("../../assets/images/icons/sent.svg");
}
.status.blocked::before {
  background-image: url("../../assets/images/icons/blocked.svg");
}
.status.waiting::before {
  background-image: url("../../assets/images/icons/waiting.svg");
}
.status.alert-gray::before {
  background-image: url("../../assets/images/icons/alert-gray.svg");
}
.status.alert-gray::before {
  background-size: 50%;
}
.status .message {
  position: relative;
  font-size: 16.5pt;
  padding-bottom: 0.6875rem;
  letter-spacing: 0.01875rem;
  margin-bottom: 0;
}
.status .message.withContent {
  padding-top: 1.1rem;
}
.status .description {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}
