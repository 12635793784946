.error {
  padding: 1rem 2rem 1rem 3rem;
  border-radius: 0.25rem;
}
.error .message {
  position: relative;
  font-size: 1.125rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
}
.error .message:before {
  content: "";
  position: absolute;
  left: -2rem;
  top: 0.125rem;
}
.error.ERROR {
  border: solid 0.0625rem #c9364d;
  background: rgba(201, 54, 77, 0.08);
}
.error.ERROR .message:before {
  content: url("../../assets/images/icons/alert.svg");
}
.error.WARNING {
  border: solid 0.0625rem #da784c;
  background: rgba(218, 120, 76, 0.08);
}
.error.WARNING .message:before {
  content: url("../../assets/images/icons/alert-orange.svg");
}
.error .description {
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
}

.systemError {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 0.7rem 0;
  background-color: #c9364d;
}
.systemError .message {
  position: relative;
  padding-left: 2.5rem;
  margin: 0;
  color: #ffffff;
}
.systemError .message:before {
  content: url("../../assets/images/icons/alert-white.svg");
  position: absolute;
  top: -0.1rem;
  left: 0;
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background: white;
}
.errorContainer .errorMessage {
  max-width: 900px;
  margin: 0 auto;
}
.errorContainer .errorMessage div:first-child {
  padding: 0;
}
@media (max-width: 767.98px) {
  .errorContainer .errorMessage div:first-child div:last-child {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .errorContainer .errorMessage div:last-child {
    display: none;
  }
}
.errorContainer h1 {
  margin: 0;
  margin-bottom: 0.5em;
  font-size: 3rem;
}
.errorContainer h3 {
  margin: 0;
  margin-bottom: 0.5em;
}
@media (max-width: 767.98px) {
  .errorContainer {
    padding-left: 0;
    width: 100%;
  }
}
.errorContainer img {
  margin: 0 auto 0 20;
  width: 100%;
  height: auto;
}
@media (max-width: 767.98px) {
  .errorContainer img {
    display: none;
  }
}

.notFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}
.notFoundContainer .notFound {
  position: relative;
  max-width: 38rem;
  margin: 0 auto;
  padding-left: 12rem;
}
@media (max-width: 767.98px) {
  .notFoundContainer .notFound {
    padding-left: 0;
    text-align: center;
  }
}
.notFoundContainer .notFound:before {
  content: url("../../assets/images/404.svg");
  position: absolute;
  left: 0;
}
@media (max-width: 767.98px) {
  .notFoundContainer .notFound:before {
    display: none;
  }
}
.notFoundContainer .notFound img {
  float: left;
  margin: 1rem 2rem 0 0;
}
@media (max-width: 767.98px) {
  .notFoundContainer .notFound img {
    display: none;
  }
}
.notFoundContainer .notFound h1 {
  margin: 0;
  font-size: 1.75rem;
}
.notFoundContainer .notFound p {
  font-size: 1.125rem;
  font-weight: 300;
}
