.header {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  background-color: #ffffff;
  box-shadow: 0 1rem 1rem -0.75rem rgba(163, 167, 186, 0.5);
}
.header.negativeBalance {
  padding: 2rem 0;
}
@media (max-width: 767.98px) {
  .header {
    padding-bottom: 1rem;
  }
}

.memberTilesArea {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  background-color: #ffffff;
}

.yearDivider {
  display: block;
  margin: 1.5625rem 0;
  border-bottom: solid 0.0625rem rgba(163, 167, 186, 0.4);
  line-height: 0;
  text-align: center;
  font-size: 0.8125rem;
  color: #6d7288;
}
.yearDivider .year {
  padding: 0 0.625rem;
  background-color: #f7f7f7;
}

.heading {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 1.125rem;
}

.subheading, .dateSmall {
  margin: 0;
  font-size: 0.8125rem;
  color: #a3a7ba;
  line-height: 1.5rem;
}

.monthItem {
  float: left;
  text-align: center;
}
@media (max-width: 767.98px) {
  .monthItem {
    display: none;
  }
}
.monthItem .month {
  text-transform: uppercase;
  margin: 0;
}

.transactionData {
  float: left;
  margin: 0 1rem;
}
@media (max-width: 767.98px) {
  .transactionData {
    margin: 0;
  }
}

.dateItem {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.fixedList {
  position: fixed;
  top: 4.5rem;
  background-color: #f7f7f7;
  z-index: 2;
  padding: 1rem 0;
}
@media (max-width: 991.98px) {
  .fixedList {
    top: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .fixedList {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 576px) {
  .fixedList.fullMembersView {
    top: 4.5rem;
  }
}
.fixedList.fullMembersView {
  top: 6.5rem;
}
.fixedList.withErrors {
  top: 7.4rem;
}
.fixedList.fullMembersViewWithError {
  top: 9.4rem;
}
@media (max-width: 991.98px) {
  .fixedList.fullMembersViewWithError {
    top: 7.4rem;
  }
}

.list {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 575.98px) {
  .list .tabs {
    padding: 0;
  }
}
.list.fixed {
  margin-top: 0;
  margin-bottom: 0;
}

.fixedContainer {
  padding-top: 3.5rem;
}

.dateSmall {
  display: none;
  margin: 0;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .dateSmall {
    display: block;
  }
}

.expiration {
  display: inline-block;
  font-weight: normal;
}
@media (max-width: 767.98px) {
  .expiration {
    display: none;
  }
}

.items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #a3a7ba;
}
@media (max-width: 767.98px) {
  .items {
    display: none;
  }
}

.alignStatus {
  margin: 4rem 0;
}
@media only screen and (max-width: 770px) {
  .alignStatus {
    margin: 0;
  }
}

.preLine {
  white-space: pre-line;
}
@media (max-width: 767.98px) {
  .preLine button {
    width: 100%;
    margin: 0.2rem 0;
  }
}

.errorView {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.errorView button {
  background-color: #ffffff;
}
@media (max-width: 575.98px) {
  .errorView button {
    width: 100%;
    margin: 0.2rem 0;
  }
}

.loadMore {
  display: flex;
  justify-content: center;
}
.loadMore button {
  background-color: #ffffff;
}

.balanceLabel {
  font-size: 1.5rem;
}

@media (max-width: 767.98px) {
  .balanceData {
    display: flex;
    flex-direction: column-reverse;
  }
}
.balanceData .balanceAmount {
  display: inline-block;
  float: left;
  margin-bottom: 1.5rem;
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 2.5rem;
}
@media (max-width: 767.98px) {
  .balanceData .balanceAmount {
    float: none;
    display: block;
    margin-bottom: initial;
    padding-bottom: 1rem;
    order: 1;
  }
}
.balanceData .optionalInfo {
  clear: both;
}
.balanceData .optionalInfo p {
  margin: 0.3rem;
  font-size: 0.75rem;
  color: #6d7288;
  text-transform: uppercase;
}
.balanceData .optionalInfo p b {
  margin-left: 0.4rem;
  color: #41444f;
  font-size: 0.875rem;
  text-transform: none;
}
.balanceData .controls {
  float: left;
  margin-left: 2rem;
}
@media (max-width: 767.98px) {
  .balanceData .controls {
    float: none;
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .balanceData .controls a,
  .balanceData .controls button {
    width: 100%;
    margin: 0.3125rem 0;
  }
}

.folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  text-align: center;
}
.folder:before {
  content: url(../../../assets/images/folder.svg);
  margin-bottom: 2.5rem;
}
.folder .message {
  padding: 0;
  font-weight: 500;
  font-size: 1.125rem;
  color: #41444f;
  opacity: 0.6;
}

.listMessage {
  background-color: #d4f5ed;
  color: #666666;
  margin: 1rem 0.2rem;
  padding: 1.2rem;
  border-radius: 0.5rem;
  font-size: medium;
}

.disputedTitle {
  margin: 0.3rem;
  font-size: 0.75rem;
  color: #6d7288;
  text-transform: uppercase;
}

.disputedContent {
  margin-left: 0.4rem;
  color: #41444f;
  font-size: 0.875rem;
  text-transform: none;
}

.optionalInfo {
  clear: both;
}
.optionalInfo p {
  margin: 0.3rem;
  color: #41444f;
  font-weight: bold;
}

.noEmailMessage {
  color: #c9364d;
  font-size: 0.6875rem;
  font-weight: 300;
  margin-top: 0.125rem;
}

.usernameContainer {
  background-color: #FEF3EB;
  border: 1px solid #E4650A;
  border-radius: 4px;
  padding: 16px 56px;
  width: 800px;
}

.usernameTitle {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #373A3C;
  line-height: 21px;
  margin-bottom: 12px;
}
.usernameTitle:before {
  position: absolute;
  left: -2.3rem;
  content: url("../../../assets/images/icons/alert-gray.svg");
}

.usernameDescription {
  font-size: 16px;
  font-weight: 400;
  color: #41444F;
  line-height: 32px;
}
