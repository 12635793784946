.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.4375rem;
  text-align: center;
}
.loader > div:first-child {
  width: 100%;
}
.loader h1 {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #373a3c;
}
.loader img {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
}
