.toast {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  padding: 1.05rem 1.25rem;
  min-width: 20rem;
  max-width: 20rem;
  border-radius: 0.125rem;
  box-shadow: 0 0.125rem 0.6875rem 0 rgba(163, 167, 186, 0.49);
  background: #ffffff;
  z-index: 1051;
}
@media (max-width: 991.98px) {
  .toast {
    min-width: 100%;
  }
}
.toast:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0.75rem;
  margin-top: -1rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
}
.toast.SAVE:before {
  background: #25a584 url("./../../assets/images/icons/white-check.svg") no-repeat 0.46rem 0.5375rem;
}
.toast.REQUEST:before {
  background: #25a584 url("./../../assets/images/icons/sent-white.svg") no-repeat 0.375rem 0.4375rem;
}
.toast .icon {
  border-radius: 50%;
  padding: 0.625rem;
}
.toast .icon .iconImage {
  width: 1.5rem;
  height: 1.5rem;
}
.toast.SUCCESS .icon {
  background-color: #25a584;
}
.toast.ERROR:before {
  background: #c9364d url("./../../assets/images/icons/white-error.svg") no-repeat 0.49rem 0.5175rem;
}
.toast.ERROR .icon {
  background-color: #c9364d;
}
.toast .message {
  position: relative;
  margin: 0;
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 500;
}
