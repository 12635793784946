.terms {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #41444f;
}

.lastUpdate {
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: solid 0.0625rem #dedede;
  font-size: 1rem;
  color: #41444f;
}
