@media (max-width: 991.98px) {
  .navigationWrapper {
    display: none;
  }
}
.navigationWrapper .navigation {
  position: fixed;
  width: 350px;
}
@media (max-width: 1199.98px) {
  .navigationWrapper .navigation {
    width: 290px;
  }
}
@media (max-width: 991.98px) {
  .navigationWrapper .navigation {
    width: 210px;
  }
}
@media (max-width: 767.98px) {
  .navigationWrapper .navigation {
    width: 150px;
  }
}
@media (max-width: 575.98px) {
  .navigationWrapper .navigation {
    position: static;
    width: initial;
    margin-bottom: 1.5rem;
    display: none;
  }
}

.anchor::before {
  display: block;
  content: "";
  margin-top: -4.5rem;
  height: 4.5rem;
  visibility: hidden;
  pointer-events: none;
}

.information {
  padding: 2.5rem 2rem;
}
@media (max-width: 767.98px) {
  .information {
    padding: 1.2rem 1rem;
  }
}
.information .heading {
  margin-bottom: 1.25rem;
  font-weight: bold;
  font-size: 1.125rem;
}
.information .label {
  color: #a3a7ba;
  font-size: 0.8125rem;
  margin-bottom: 0.4375rem;
  font-weight: bold;
}
.information .label.phone {
  padding-left: 0.5rem;
  padding-right: 0.25rem;
}
.information .data {
  color: #41444f;
  font-size: 0.9375rem;
}
.information .address p {
  margin-bottom: 0.25rem;
}
.information hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.information .emailSent {
  margin-top: 0.5rem;
  padding: 1rem;
  font-size: 15px;
  background-color: rgba(163, 167, 186, 0.1);
  border: dashed 1px #a3a7ba;
  border-radius: 4px;
}
@media (max-width: 767.98px) {
  .information .emailSent button {
    width: 100%;
    margin: 0.25rem 0;
  }
  .information .emailSent button:last-child {
    margin-bottom: 0;
  }
}
.information .upperCaseLabel {
  text-transform: uppercase;
  color: #6d7288;
  font-weight: 500;
  font-size: 0.75rem;
}
.information .accessBlock {
  margin-bottom: 2rem;
}
.information .accessBlock .accessMember:nth-child(1n) .memberInfo .userShortName {
  background-color: #dfe0f6;
}
.information .accessBlock .accessMember:nth-child(2n) .memberInfo .userShortName {
  background-color: #fdd8e1;
}
.information .accessBlock .accessMember:nth-child(3n) .memberInfo .userShortName {
  background-color: #e8d9f6;
}
.information .accessBlock .accessMember:nth-child(4n) .memberInfo .userShortName {
  background-color: #dfe0f6;
}
.information .accessBlock .accessMember {
  padding: 0.7rem 0;
  border-bottom: solid 0.0625rem rgba(163, 167, 186, 0.3);
}
.information .accessBlock .accessMember:last-child {
  border: none;
}
.information .accessBlock .accessMember .memberInfo {
  display: flex;
}
.information .accessBlock .accessMember .memberInfo .userShortName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.25rem;
  color: #373a3c;
  font-weight: normal;
}
.information .accessBlock .accessMember .memberInfo .userName {
  font-size: 0.9375rem;
}
.information .accessBlock .accessMember .memberInfo .userName span {
  display: block;
  font-size: 13px;
  color: rgba(65, 68, 79, 0.6);
}
.information .accessBlock .accessMember .memberControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 767.98px) {
  .information .accessBlock .accessMember .memberControls {
    flex-direction: column-reverse;
  }
  .information .accessBlock .accessMember .memberControls button,
  .information .accessBlock .accessMember .memberControls a {
    margin: 0.3rem 0;
    width: 100%;
  }
}

.userNameRow {
  display: flex;
  align-items: center;
}
