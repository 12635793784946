.toastsContainer {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
}
@media (max-width: 991.98px) {
  .toastsContainer {
    width: 100%;
    bottom: -0.5rem;
    left: 0;
  }
}
