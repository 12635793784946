.buttonPadding {
  margin: 0 0 0.5rem 0;
}

.warningMessage {
  color: #c9364d;
}

.heading {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.9375rem;
}

.subHeading {
  font-size: 1.125rem;
  padding-bottom: 0;
}

.description {
  font-size: small;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.displayedCard {
  margin: 8px auto;
  font-size: small;
  text-align: center;
  background-color: rgba(163, 167, 186, 0.4);
  border: 0;
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.displayedCard:focus {
  outline: none;
}

.displayedCard svg {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 235px;
}

.terms {
  padding: 0 1rem 1rem 0;
  overflow-y: auto;
  max-height: 30rem;
}
@media (max-width: 991.98px) {
  .terms {
    max-height: 14rem;
  }
}

.termsControls {
  border-top: solid 0.0625rem rgba(163, 167, 186, 0.4);
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin: 0 -1.5rem;
}
.termsControls .termsButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #a3a7ba;
}
@media (max-width: 991.98px) {
  .termsControls .termsButtons {
    flex-direction: column;
  }
  .termsControls .termsButtons button {
    width: 100%;
  }
  .termsControls .termsButtons button:first-child {
    margin: 0 0 0.5rem 0;
  }
}

@media (max-width: 991.98px) {
  .modalControls {
    padding-top: 0;
    flex-direction: column;
  }
  .modalControls button {
    width: 100%;
  }
  .modalControls button:first-child {
    margin: 0 0 0.5rem 0;
  }
}

.detailsView {
  margin-bottom: 0.5rem;
}
.detailsView a {
  text-decoration: underline;
}
.detailsView .label {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #a3a7ba;
}
.detailsView .value {
  font-size: 0.9375rem;
  font-weight: 100;
}

.modal-table {
  margin-bottom: 1.5rem;
}
.modal-table table thead th:first-child {
  padding-left: 1.5rem;
}
.modal-table table tbody td:first-child {
  padding-left: 1.5rem;
}

.linkView {
  text-decoration: underline;
  color: #25a584;
  cursor: pointer;
}

.modalFooter {
  flex-grow: 1;
}
.modalFooter .innerFooter {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.modalFooter .innerFooter button {
  width: 100%;
}
.modalFooter .innerFooter button:first-child {
  margin: 0 0 0.5rem 0;
}
@media (max-width: 767.98px) {
  .modalFooter .innerFooter {
    padding-top: 0;
  }
}

.modalInfo {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
}
