@media (max-width: 991.98px) {
  ul.languages {
    display: none;
  }
}
ul.settings {
  display: inline-flex;
  justify-content: flex-end;
}
ul.settings .dropDown .toggle .label {
  display: inline;
}
@media (max-width: 991.98px) {
  ul.settings .dropDown .toggle .label {
    display: none;
  }
}
@media (max-width: 991.98px) {
  ul.settings .dropDown .toggle {
    padding: 0;
    border-radius: 50%;
  }
  ul.settings .dropDown .toggle:hover {
    border-radius: 50%;
  }
  ul.settings .dropDown .toggle:hover:after {
    background-color: #cfd1db;
  }
  ul.settings .dropDown .toggle:after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    left: 0;
    top: 0;
    border-radius: 50%;
    background: #dddfe6 url(./../../assets/images/icons/settings.svg) no-repeat center center;
  }
}

ul.profileSettings {
  display: inline-flex;
  justify-content: flex-end;
}
ul.profileSettings .dropDown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: url(./../../assets/images/icons/avatar.svg) no-repeat center center;
}

.minWidth {
  min-width: 2.5rem;
}

.mobileNav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.mobileNav .mobileMenu {
  background: url(../../assets/images/icons/menu.svg) no-repeat center;
  background-color: white;
  border: none;
  margin-right: 0 !important;
}
.mobileNav .mobileMenu .dropdown-item a {
  padding: 0;
}
