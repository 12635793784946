.header {
  display: flex;
  background: #ffffff;
  align-items: center;
  height: 4.5rem;
}
.header.withShadow {
  box-shadow: 0 0.125rem 0.75rem 0 rgba(158, 173, 189, 0.5);
}
.header.members {
  padding: 1.3125rem 0;
}
.header.members.withFullMembersView {
  padding-top: 1rem;
  padding-bottom: 2rem;
  height: 6.5rem;
}
.header.members.withFixedError {
  margin-top: 2.3125rem;
}
.header.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  padding: 0;
  z-index: 3;
}
.header.fixed.fixedError {
  top: 2.8984375rem;
}
.header .controls {
  display: flex;
  align-items: center;
}
.header .controls.buttons {
  justify-content: flex-start;
}
@media (max-width: 767.98px) {
  .header .controls.buttons {
    justify-content: center;
  }
}
.header .controls.message {
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .header .controls.message {
    display: none;
  }
}
.header .controls.message .unsaved {
  font-size: 0.875rem;
  color: #a3a7ba;
  padding-right: 1rem;
}
.header .active {
  border-bottom: solid 0.125rem #25a584;
}
.header .mainHeaderRow {
  justify-content: space-between;
  align-items: center;
}
.header .mainHeaderRow .employerData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header .mainHeaderRow .employerData .employerName {
  display: flex;
  align-items: center;
  height: 2rem;
  margin: 0;
  line-height: 1rem;
  font-size: 1rem;
  padding-left: 1rem;
  border-left: 0.0625rem solid #a3a7ba;
}
@media (max-width: 991.98px) {
  .header .mainHeaderRow .employerData {
    display: none;
  }
}
.header .mainHeaderRow .hbfLogo {
  max-width: 9.25rem;
  margin-right: 1rem;
}
.header .mainHeaderRow .employerLogo {
  padding-left: 1rem;
  max-width: 10rem;
  max-height: 2rem;
  border-left: 0.0625rem solid #a3a7ba;
}
.header .mainHeaderRow .logoBreadcrumb {
  align-items: center;
  justify-content: space-between;
}
.header .mainHeaderRow .statementsActions {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .header .mainHeaderRow .statementsActions {
    display: none;
  }
}
.header .mainHeaderRow .statementsMobileActions {
  display: none;
}
@media (max-width: 767.98px) {
  .header .mainHeaderRow .statementsMobileActions {
    display: flex;
    justify-content: flex-end;
  }
}

.mainContent {
  padding-bottom: 4.5rem;
  background-color: inherit;
}
.mainContent.withErrors {
  padding-top: 6.8125rem;
}
.mainContent.fullMembersViewWithError {
  padding-top: 8.5rem;
}
@media (max-width: 991.98px) {
  .mainContent.fullMembersViewWithError {
    padding-top: 7.5rem;
  }
}

@media (min-width: 0px) and (max-width: 481px) {
  .mainContent {
    padding-top: 4.5rem;
  }
  .mainContent.fullMembersView {
    top: 4.5rem;
  }
}
@media (min-width: 482px) {
  .mainContent {
    padding-top: 6.5rem;
  }
  .mainContent.fullMembersView {
    top: 6.5rem;
  }
}
