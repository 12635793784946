@media (min-width: 992px) {
  main > .paymentContainer {
    max-width: 50rem;
  }
}
.paymentContainer .dialogBox {
  position: relative;
  background: rgba(37, 165, 132, 0.08);
  border: 0.063rem solid #25A584;
  border-radius: 0.25rem;
  padding-bottom: 1rem;
  padding-top: 0.938rem;
  padding-left: 3.5rem;
}
.paymentContainer .dialogBox .icon {
  height: 2rem;
  width: 2.375rem;
  position: absolute;
  background: transparent url("../../assets/images/icons/info.svg") no-repeat center;
  background-size: contain;
  top: 5%;
  left: 1.5%;
}
.paymentContainer .dialogBox button {
  background: #FFFFFF;
  border: 0.063rem solid #A3A7BA;
  border-radius: 0.25rem;
}
.paymentContainer .dialogBox .dialogText {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #41444F;
  white-space: pre-line;
  margin-bottom: 0.938rem;
}
.paymentContainer .dialogBox .dialogTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 21px;
  color: #373A3C;
  margin-bottom: 0.813rem;
}
.paymentContainer .blockHeader .paymentStep {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paymentContainer .blockHeader .paymentStep h1 {
  display: flex;
  align-items: center;
  margin: 0;
  color: #373a3c;
  font-weight: 500;
  font-size: 1.125rem;
}
.paymentContainer .blockHeader .paymentStep h1 .stepNumber {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  width: 1.75rem;
  border: 0.0625rem solid #a3a7ba;
  color: #41444f;
  font-weight: 400;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}
.paymentContainer .blockHeader .paymentStep h1 .stepNumber .passed {
  line-height: 2;
  background: transparent url("../../assets/images/icons/check.svg") no-repeat center;
  background-size: contain;
}
.paymentContainer .blockHeader .stepSummary {
  margin: 0;
  padding-left: 2.3rem;
}
.paymentContainer .blockHeader .stepSummary span {
  font-weight: 500;
}
.paymentContainer .blockHeader.active {
  margin-bottom: 2rem;
}
.paymentContainer .blockHeader.active h1 .stepNumber {
  background: #25a584;
  color: #ffffff;
  border: 0.125rem solid #a8dbce;
}
.paymentContainer .blockHeader.passedStep h1 {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6d7288;
}
.paymentContainer .blockHeader.passedStep .stepNumber {
  background: #ffffff url("../../assets/images/icons/check.svg") no-repeat center;
  background-size: 60%;
  color: #ffffff;
  border: 0.0625rem solid #25a584;
}
.paymentContainer fieldset {
  margin: 0;
}
.paymentContainer .mpdRecurring {
  position: relative;
  padding-left: 2rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.paymentContainer .mpdRecurring:before {
  content: "";
  background: transparent url("../../assets/images/icons/info.svg") no-repeat center;
  width: 2.5rem;
  height: 2.5rem;
  background-size: contain;
  position: absolute;
  left: -0.6rem;
  top: -0.6rem;
}
@media (max-width: 767.98px) {
  .paymentContainer .mpdRecurring {
    display: none;
  }
}
.paymentContainer .dates.calendarLegend .legend .selectDate {
  border: 1px solid rgba(163, 167, 186, 0.4);
}
.paymentContainer .dates.calendarLegend .legend:first-child .selectDate {
  background: rgba(163, 167, 186, 0.3);
}
.paymentContainer .dates .selectDate {
  width: 2.3125rem;
  height: 2.3125rem;
  margin: 0.46875rem;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  border: solid 0.0625rem #a3a7ba;
  border-radius: 0.25rem;
  overflow: hidden;
}
.paymentContainer .dates .selectDate.small {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
.paymentContainer .dates .selectDate.medium {
  width: 2.25rem;
  height: 2.25rem;
  margin: 0px 6px 12px;
}
.paymentContainer .dates .selectDate div {
  height: inherit;
  padding: 0;
  overflow: hidden;
}
.paymentContainer .dates .selectDate div label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.paymentContainer .dates .selectDate.disabled {
  border-color: #a3a7ba;
  pointer-events: none;
}
.paymentContainer .dates .selectDate.stroke {
  background: repeating-linear-gradient(45deg, #c6c8d4, #c6c8d4 0.125rem, #dddfe6 0.125rem, #dddfe6 0.4375rem);
}
.paymentContainer .dates .selectDate.dueDate {
  position: relative;
  border-color: #a3a7ba;
}
.paymentContainer .dates .selectDate.dueDate:after {
  content: "";
  position: absolute;
  right: 0.1875rem;
  top: 0.1875rem;
  width: 0.4375rem;
  height: 0.4375rem;
  border-radius: 50%;
  background-color: #fd7e14;
}
.paymentContainer .dates .selectDate.statementDate {
  position: relative;
}
.paymentContainer .dates .selectDate.statementDate:after {
  content: "";
  position: absolute;
  right: 0.1875rem;
  top: 0.1875rem;
  width: 0.4375rem;
  height: 0.4375rem;
  border-radius: 50%;
  background-color: #25a584;
}
.paymentContainer .dates .selectDate.checked {
  background-color: #e9faf5;
  border-color: #25a584;
}
.paymentContainer .dates .dateError {
  display: block;
  margin: 0.46875rem;
}
.paymentContainer .dates .legend {
  display: flex;
  align-items: center;
}
.paymentContainer .dates .legend:first-child {
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .paymentContainer .dates .legend:last-child {
    margin-bottom: 1rem;
  }
}
.paymentContainer .dates .legend span {
  padding-top: 0.1875rem;
  font-size: 0.875rem;
  color: rgba(65, 68, 79, 0.8);
}
.paymentContainer .messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.paymentContainer .messages .message {
  position: relative;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  color: rgba(65, 68, 79, 0.8);
}
.paymentContainer .messages .message:before {
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.8em;
  left: -1.5rem;
  background: url("../../assets/images/icons/valid.svg") no-repeat center;
}
.paymentContainer .messages .message.error:before {
  background-image: url("../../assets/images/icons/error.svg");
}
.paymentContainer .cards {
  background: url("../../assets/images/icons/visa.svg") 35rem center no-repeat, url("../../assets/images/icons/mastercard.svg") 37rem center no-repeat, url("../../assets/images/icons/amex.svg") 39rem center no-repeat, url("../../assets/images/icons/discover.svg") 41rem center no-repeat;
}
@media (max-width: 991.98px) {
  .paymentContainer .cards {
    background: url("../../assets/images/icons/visa.svg") 30rem center no-repeat, url("../../assets/images/icons/mastercard.svg") 32rem center no-repeat, url("../../assets/images/icons/amex.svg") 34rem center no-repeat, url("../../assets/images/icons/discover.svg") 36rem center no-repeat;
  }
}
@media (max-width: 767.98px) {
  .paymentContainer .cards {
    background: none;
  }
}
.paymentContainer .eCheck {
  font-weight: 500;
}
.paymentContainer .card {
  position: relative;
  padding-left: 2.2rem;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .paymentContainer .card {
    padding: 0;
  }
}
.paymentContainer .card:before {
  content: url("../../assets/images/icons/card-placeholder.svg");
  position: absolute;
  top: 0.1rem;
  left: 0;
  background: transparent no-repeat center;
}
@media (max-width: 767.98px) {
  .paymentContainer .card:before {
    content: "";
  }
}
.paymentContainer .card.MasterCard:before {
  content: url("../../assets/images/icons/mastercard.svg");
}
@media (max-width: 767.98px) {
  .paymentContainer .card.MasterCard:before {
    content: "";
  }
}
.paymentContainer .card.Visa:before {
  content: url("../../assets/images/icons/visa.svg");
}
@media (max-width: 767.98px) {
  .paymentContainer .card.Visa:before {
    content: "";
  }
}
.paymentContainer .card.AmericanExpress:before {
  content: url("../../assets/images/icons/amex.svg");
}
@media (max-width: 767.98px) {
  .paymentContainer .card.AmericanExpress:before {
    content: "";
  }
}
.paymentContainer .card.Discover:before {
  content: url("../../assets/images/icons/discover.svg");
}
@media (max-width: 767.98px) {
  .paymentContainer .card.Discover:before {
    content: "";
  }
}
.paymentContainer .card .expDate {
  padding-left: 0.5rem;
  color: #6d7288;
  font-weight: normal;
}
.paymentContainer .cardInput {
  position: relative;
}
.paymentContainer .cardInput:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 2.2rem;
  left: 0.5rem;
  width: 2rem;
  height: 1.5rem;
  background: transparent no-repeat center;
}
.paymentContainer .cardInput:before {
  background-image: url("../../assets/images/icons/card-placeholder.svg");
}
.paymentContainer .cardInput input {
  padding-left: 3rem;
}
.paymentContainer .cardInput.MasterCard::before {
  background-image: url("../../assets/images/icons/green-mastercard.svg");
}
.paymentContainer .cardInput.Visa::before {
  background-image: url("../../assets/images/icons/green-visa.svg");
}
.paymentContainer .cardInput.AmericanExpress::before {
  background-image: url("../../assets/images/icons/green-amex.svg");
}
.paymentContainer .cardInput.Discover::before {
  background-image: url("../../assets/images/icons/green-discover.svg");
}
.paymentContainer .accountTypeSwitcher {
  width: 100%;
}
.paymentContainer .accountTypeSwitcher button {
  width: 50%;
}
.paymentContainer .submitButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 767.98px) {
  .paymentContainer .submitButton {
    display: block;
    margin-bottom: 1rem;
    text-align: center;
  }
  .paymentContainer .submitButton button {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: 100%;
  }
}
.paymentContainer .submitButton .savedPaymentInfo {
  padding-right: 1rem;
  color: #6d7288;
  font-size: 0.875rem;
}
@media (max-width: 991.98px) {
  .paymentContainer .paymentImage {
    display: none;
  }
}
.paymentContainer .calendar {
  width: 100%;
}
.paymentContainer .calendar .month {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.paymentContainer .calendar .month .calendarHeaderRight {
  display: flex;
  align-items: center;
}
.paymentContainer .calendar .month .calendarHeaderLeft {
  display: flex;
  align-items: center;
}
.paymentContainer .calendar .month .angleLeft {
  background: transparent url("../../assets/images/icons/angle-left-black.svg") no-repeat center;
  background-size: contain;
  height: 0.563rem;
  width: 0.875rem;
  cursor: pointer;
}
.paymentContainer .calendar .month .angleLeft.disabled {
  background: transparent url("../../assets/images/icons/angle-left.svg") no-repeat center;
  background-size: contain;
  cursor: none;
  pointer-events: none;
}
.paymentContainer .calendar .month .angleRight {
  background: transparent url("../../assets/images/icons/angle-left-black.svg") no-repeat center;
  background-size: contain;
  height: 0.563rem;
  width: 0.875rem;
  transform: rotate(180deg);
  cursor: pointer;
}
.paymentContainer .calendar .date {
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.313rem;
  color: #373A3C;
  text-align: center;
  margin: 0 auto;
  width: 18.5rem;
}
.paymentContainer .calendar .weekdays {
  width: 21rem;
  height: 2rem;
  display: flex;
  margin-bottom: 0.313rem;
  margin-top: 1.438rem;
}
.paymentContainer .calendar .weekdays div {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  width: 2.25rem;
  margin: 0rem 0.375rem;
  color: rgba(65, 68, 79, 0.8);
}
.paymentContainer .calendar .selectorContainer {
  display: flex;
  justify-content: space-between;
}
.paymentContainer .calendar .selectorContainer .firstCalenderSelector {
  width: 20.25rem;
}
.paymentContainer .calendar .selectorContainer .secondCalenderSelector {
  width: 20.25rem;
}
.paymentContainer .calendar .datesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 21rem;
}
.paymentContainer .calendar .datesContainer .hidden {
  visibility: hidden;
}
.paymentContainer .calendar .datesContainer .selectDate {
  border: 1px solid rgba(163, 167, 186, 0.4);
}
.paymentContainer .calendar .datesContainer .disabled {
  background: rgba(163, 167, 186, 0.3);
}

@media (max-width: 991.98px) {
  .selectContent {
    display: none;
  }
}
.selectContent .helperText {
  line-height: 1.25rem;
  font-size: 0.875rem;
  margin: 0 0 0 auto;
  color: rgba(65, 68, 79, 0.8);
}

.optionHeading {
  font-size: 1rem;
  font-weight: 500;
}
.optionHeading span {
  padding-left: 0.25rem;
  font-size: 0.875rem;
  opacity: 0.7;
  font-weight: normal;
}
@media (max-width: 767.98px) {
  .optionHeading span {
    display: none;
  }
}

.labelText {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

.amount {
  font-size: 1.375rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.amount small {
  color: #25a584;
  text-decoration: line-through;
  margin-left: 0.5rem;
}

.optionTitle {
  margin: 0;
  font-weight: 500;
}

.paymentSuccess {
  margin-top: 8rem;
}
.paymentSuccess button,
.paymentSuccess a {
  margin: 0.25rem 0;
  background-color: #ffffff;
}
@media (max-width: 767.98px) {
  .paymentSuccess button,
  .paymentSuccess a {
    width: 100%;
  }
}

#maxAmountDollarSign {
  position: absolute;
  top: 1.75rem;
  left: 1.8rem;
  font-weight: bold;
  font-size: 1.375rem;
}
#maxAmountDollarSign p {
  color: #a3a7ba;
}

#maxAmount {
  font-weight: 500;
  font-size: 1rem;
  padding-left: 1.5rem;
  background-color: white;
}

#maxAmount_error {
  color: #c9364d;
  font-size: 0.6875rem;
  font-weight: 300;
  margin-top: 0.125rem;
}
