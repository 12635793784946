.membersWidget {
  display: flex;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .membersWidget {
    display: none;
  }
}
.membersWidget .familyMembers {
  border: 0.0625rem solid #848f8c;
  border-radius: 6.25rem;
  padding: 0.5rem 1rem;
  display: inline-flex;
  height: 3.7rem;
}
.membersWidget .familyMembers button.member:nth-child(3n+1) {
  background: #dfe0f6;
}
.membersWidget .familyMembers button.member:nth-child(3n+2) {
  background: #fdd8e1;
}
.membersWidget .familyMembers button.member:nth-child(3n+3) {
  background: #e8d9f6;
}
.membersWidget .familyMembers button.member,
.membersWidget .familyMembers div.member {
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: solid 0.125rem transparent;
  background-color: #a3a7ba;
  font-size: 1.25rem;
  font-weight: normal;
  color: #373a3c;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  line-height: inherit;
  flex-direction: column;
  margin: 0 0.5rem;
  padding: 0.25rem;
}
@media (max-width: 991.98px) {
  .membersWidget .familyMembers button.member,
  .membersWidget .familyMembers div.member {
    padding: 0;
  }
}
.membersWidget .familyMembers button.member:after,
.membersWidget .familyMembers div.member:after {
  content: "";
  position: absolute;
  top: -0.4rem;
  right: -0.8rem;
  border-radius: 50%;
}
.membersWidget .familyMembers button.member.DEPENDENT:after,
.membersWidget .familyMembers div.member.DEPENDENT:after {
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff url("../../../../assets/images/icons/blocked.svg") no-repeat 0.38rem 0.185rem;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(158, 173, 189, 0.5);
}
@media (max-width: 991.98px) {
  .membersWidget .familyMembers button.member.DEPENDENT:after,
  .membersWidget .familyMembers div.member.DEPENDENT:after {
    width: 1.2rem;
    height: 1.2rem;
    background-position: 0.29rem 0.156rem;
    background-size: 50%;
  }
}
.membersWidget .familyMembers button.member.DEFAULTED:after,
.membersWidget .familyMembers div.member.DEFAULTED:after {
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff url("../../../../assets/images/icons/white-alert.svg") no-repeat -0.875rem -0.75rem;
}
.membersWidget .familyMembers button.member.active,
.membersWidget .familyMembers div.member.active {
  background: linear-gradient(225deg, #25a584 0%, #00a9a5 100%);
  border-color: #2ecfa5;
  color: #ffffff;
}
.membersWidget .familyMembers button.member.active .firstName,
.membersWidget .familyMembers div.member.active .firstName {
  color: #25a584;
}
.membersWidget .familyMembers .firstName {
  position: relative;
  font-size: 0.8125rem;
  padding-top: 0.9375rem;
  overflow: hidden;
  width: 2.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

@media (min-width: 992px) {
  .mobileMembersWidget {
    display: none;
  }
}

.dropDown button.toggle,
.dropDown div.toggle {
  display: flex;
  width: 100%;
  padding: 0.3125rem 0.4375rem;
  background-color: #ffffff;
  border: 0.0625rem solid #848f8c;
  border-radius: 6.25rem;
}
.dropDown button.toggle button.member,
.dropDown button.toggle div.member,
.dropDown div.toggle button.member,
.dropDown div.toggle div.member {
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: solid 0.125rem transparent;
  background-color: #a3a7ba;
  font-size: 1.25rem;
  font-weight: normal;
  color: #373a3c;
}
@media (max-width: 991.98px) {
  .dropDown button.toggle button.member,
  .dropDown button.toggle div.member,
  .dropDown div.toggle button.member,
  .dropDown div.toggle div.member {
    padding: 0;
  }
}
.dropDown button.toggle button.member span,
.dropDown button.toggle div.member span,
.dropDown div.toggle button.member span,
.dropDown div.toggle div.member span {
  width: 100%;
  line-height: 0;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .dropDown button.toggle button.member span,
  .dropDown button.toggle div.member span,
  .dropDown div.toggle button.member span,
  .dropDown div.toggle div.member span {
    line-height: 0;
  }
}
.dropDown button.toggle button.member:after,
.dropDown button.toggle div.member:after,
.dropDown div.toggle button.member:after,
.dropDown div.toggle div.member:after {
  content: "";
  position: absolute;
  top: -0.4rem;
  right: -0.8rem;
  border-radius: 50%;
}
.dropDown button.toggle button.member.DEPENDENT:after,
.dropDown button.toggle div.member.DEPENDENT:after,
.dropDown div.toggle button.member.DEPENDENT:after,
.dropDown div.toggle div.member.DEPENDENT:after {
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff url("../../../../assets/images/icons/blocked.svg") no-repeat 0.38rem 0.185rem;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(158, 173, 189, 0.5);
}
@media (max-width: 991.98px) {
  .dropDown button.toggle button.member.DEPENDENT:after,
  .dropDown button.toggle div.member.DEPENDENT:after,
  .dropDown div.toggle button.member.DEPENDENT:after,
  .dropDown div.toggle div.member.DEPENDENT:after {
    width: 1.2rem;
    height: 1.2rem;
    background-position: 0.29rem 0.156rem;
    background-size: 50%;
  }
}
.dropDown button.toggle button.member.DEFAULTED:after,
.dropDown button.toggle div.member.DEFAULTED:after,
.dropDown div.toggle button.member.DEFAULTED:after,
.dropDown div.toggle div.member.DEFAULTED:after {
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff url("../../../../assets/images/icons/white-alert.svg") no-repeat -0.875rem -0.75rem;
}
.dropDown button.toggle button.member.active,
.dropDown button.toggle div.member.active,
.dropDown div.toggle button.member.active,
.dropDown div.toggle div.member.active {
  background: linear-gradient(225deg, #25a584 0%, #00a9a5 100%);
  border-color: #2ecfa5;
  color: #ffffff;
}
.dropDown button.toggle button.member.active .firstName,
.dropDown button.toggle div.member.active .firstName,
.dropDown div.toggle button.member.active .firstName,
.dropDown div.toggle div.member.active .firstName {
  color: #25a584;
}
.dropDown button.toggle .firstName,
.dropDown div.toggle .firstName {
  position: relative;
  top: -2px;
  margin: 0;
  display: inline-block;
  font-size: 0.8125rem;
  padding-left: 0.8rem;
  font-weight: bold;
}
.dropDown button.toggle:after,
.dropDown div.toggle:after {
  position: absolute;
  height: 1.9rem;
  width: 2.4rem;
  right: 0;
  top: 0.6rem;
}
.dropDown .dropDownMenu {
  width: 100%;
  max-height: 23.8rem;
  overflow: auto;
}
@media (max-width: 991.98px) {
  .dropDown .dropDownMenu {
    max-height: 17.3rem;
  }
}
.dropDown .dropDownMenu .dropDownItem:nth-child(3n+1) button.member, .dropDown .dropDownMenu .dropDownItem:nth-child(3n+1) div.member {
  background: #dfe0f6;
}
.dropDown .dropDownMenu .dropDownItem:nth-child(3n+2) button.member, .dropDown .dropDownMenu .dropDownItem:nth-child(3n+2) div.member {
  background: #fdd8e1;
}
.dropDown .dropDownMenu .dropDownItem:nth-child(3n+3) button.member, .dropDown .dropDownMenu .dropDownItem:nth-child(3n+3) div.member {
  background: #e8d9f6;
}
.dropDown .dropDownMenu .dropDownItem {
  border-bottom: solid 0.0625rem #dddfe6;
}
.dropDown .dropDownMenu .dropDownItem button.member,
.dropDown .dropDownMenu .dropDownItem div.member {
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: solid 0.125rem transparent;
  background-color: #a3a7ba;
  font-size: 1.25rem;
  font-weight: normal;
  color: #373a3c;
  line-height: initial;
  padding: 0;
}
@media (max-width: 991.98px) {
  .dropDown .dropDownMenu .dropDownItem button.member,
  .dropDown .dropDownMenu .dropDownItem div.member {
    padding: 0;
  }
}
.dropDown .dropDownMenu .dropDownItem button.member span,
.dropDown .dropDownMenu .dropDownItem div.member span {
  width: 100%;
  line-height: 0;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .dropDown .dropDownMenu .dropDownItem button.member span,
  .dropDown .dropDownMenu .dropDownItem div.member span {
    line-height: 0;
  }
}
.dropDown .dropDownMenu .dropDownItem button.member:after,
.dropDown .dropDownMenu .dropDownItem div.member:after {
  content: "";
  position: absolute;
  top: -0.4rem;
  right: -0.8rem;
  border-radius: 50%;
}
.dropDown .dropDownMenu .dropDownItem button.member.DEPENDENT:after,
.dropDown .dropDownMenu .dropDownItem div.member.DEPENDENT:after {
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff url("../../../../assets/images/icons/blocked.svg") no-repeat 0.38rem 0.185rem;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(158, 173, 189, 0.5);
}
@media (max-width: 991.98px) {
  .dropDown .dropDownMenu .dropDownItem button.member.DEPENDENT:after,
  .dropDown .dropDownMenu .dropDownItem div.member.DEPENDENT:after {
    width: 1.2rem;
    height: 1.2rem;
    background-position: 0.29rem 0.156rem;
    background-size: 50%;
  }
}
.dropDown .dropDownMenu .dropDownItem button.member.DEFAULTED:after,
.dropDown .dropDownMenu .dropDownItem div.member.DEFAULTED:after {
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff url("../../../../assets/images/icons/white-alert.svg") no-repeat -0.875rem -0.75rem;
}
.dropDown .dropDownMenu .dropDownItem .firstName {
  position: relative;
  top: -2px;
  margin: 0;
  display: inline-block;
  font-size: 0.8125rem;
  padding-left: 0.8rem;
  font-weight: bold;
}
.dropDown .dropDownMenu .dropDownItem:last-child {
  border-bottom: none;
}
.dropDown .dropDownMenu .dropDownItem.selected {
  position: relative;
  background-color: rgba(37, 165, 132, 0.2);
}
.dropDown .dropDownMenu .dropDownItem.selected button.member,
.dropDown .dropDownMenu .dropDownItem.selected div.member {
  background: linear-gradient(225deg, #25a584 0%, #00a9a5 100%);
  border-color: #2ecfa5;
  color: #ffffff;
}
.dropDown .dropDownMenu .dropDownItem.selected button.member .firstName,
.dropDown .dropDownMenu .dropDownItem.selected div.member .firstName {
  color: #25a584;
}
.dropDown .dropDownMenu .dropDownItem.selected:after {
  content: url("../../../../assets/images/icons/check.svg");
  position: absolute;
  right: 0.5rem;
  top: 0.9rem;
  width: 1.5rem;
  height: 1.5rem;
}

#previousArrowContainer {
  display: flex;
  justify-content: flex-end;
}
#previousArrowContainer #previousArrow {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background: url(./../../../../assets/images/icons/chevron-left.svg) no-repeat center;
  border: none;
  box-shadow: 0px 1px 5px rgb(158, 173, 189);
}
#previousArrowContainer #previousArrow:focus {
  outline: none;
}
#previousArrowContainer #previousArrow:disabled {
  background-color: lightgray;
}

#nextArrow {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background: url(./../../../../assets/images/icons/chevron-right.svg) no-repeat center;
  border: none;
  box-shadow: 0px 1px 5px rgb(158, 173, 189);
}
#nextArrow:focus {
  outline: none;
}

.memberTileContainer {
  display: flex;
  width: 100%;
}
.memberTileContainer .memberTile, .memberTileContainer .currentMemberTile {
  display: flex;
  flex-direction: column;
  height: 7.5rem;
  font-size: 0.875rem;
  box-shadow: 0px 1px 5px rgba(158, 173, 189, 0.485309);
  border-radius: 0.5rem;
  padding: 0.875rem;
  cursor: pointer;
}
.memberTileContainer .memberTile:focus, .memberTileContainer .currentMemberTile:focus {
  outline: none;
}
.memberTileContainer .memberTile:nth-of-type(10n-9) .tileHeader .firstNameLetterBubble, .memberTileContainer .currentMemberTile:nth-of-type(10n-9) .tileHeader .firstNameLetterBubble {
  background-color: #dfe0f6;
}
.memberTileContainer .memberTile:nth-of-type(10n-8), .memberTileContainer .currentMemberTile:nth-of-type(10n-8) {
  margin-left: 1rem;
}
.memberTileContainer .memberTile:nth-of-type(10n-8) .tileHeader .firstNameLetterBubble, .memberTileContainer .currentMemberTile:nth-of-type(10n-8) .tileHeader .firstNameLetterBubble {
  background-color: #fdd8e1;
}
.memberTileContainer .memberTile:nth-of-type(10n-7), .memberTileContainer .currentMemberTile:nth-of-type(10n-7) {
  margin-left: 1rem;
}
.memberTileContainer .memberTile:nth-of-type(10n-7) .tileHeader .firstNameLetterBubble, .memberTileContainer .currentMemberTile:nth-of-type(10n-7) .tileHeader .firstNameLetterBubble {
  background-color: #e8d9f6;
}
.memberTileContainer .memberTile:nth-of-type(10n-6), .memberTileContainer .currentMemberTile:nth-of-type(10n-6) {
  margin-left: 1rem;
}
.memberTileContainer .memberTile:nth-of-type(10n-6) .tileHeader .firstNameLetterBubble, .memberTileContainer .currentMemberTile:nth-of-type(10n-6) .tileHeader .firstNameLetterBubble {
  background-color: #a1ffe9;
}
.memberTileContainer .memberTile .tileHeader, .memberTileContainer .currentMemberTile .tileHeader {
  display: flex;
}
.memberTileContainer .memberTile .tileHeader .iconContainer, .memberTileContainer .currentMemberTile .tileHeader .iconContainer {
  margin-left: auto;
}
.memberTileContainer .memberTile .tileHeader .iconContainer .optOut, .memberTileContainer .currentMemberTile .tileHeader .iconContainer .optOut {
  margin-right: 0 !important;
  height: 2rem;
  width: 2rem;
  background-color: rgba(228, 101, 10, 0.08) !important;
  border: 1px solid #e4650a;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  background: url(./../../../../assets/images/icons/grey-warning.svg) no-repeat center;
}
.memberTileContainer .memberTile .tileHeader .iconContainer .noAccess, .memberTileContainer .currentMemberTile .tileHeader .iconContainer .noAccess {
  margin-right: 0 !important;
  justify-self: end;
  height: 2.25rem;
  width: 2.25rem;
  background-color: rgba(173, 175, 178, 0.08);
  border: 1px solid #adafb2;
  box-sizing: border-box;
  border-radius: 4px;
  background: url(./../../../../assets/images/icons/grey-lock.svg) no-repeat center;
}
.memberTileContainer .memberTile .tileHeader .firstNameLetterBubble, .memberTileContainer .currentMemberTile .tileHeader .firstNameLetterBubble {
  display: flex;
  justify-content: center;
  margin-right: 0.75rem;
  width: 2rem;
  height: 2rem;
  background: lightpink;
  border-radius: 1rem;
  font-size: 0.875rem;
  line-height: 2rem;
  font-weight: 500;
}
.memberTileContainer .memberTile .tileHeader .firstNameLetterBubbleActive, .memberTileContainer .currentMemberTile .tileHeader .firstNameLetterBubbleActive {
  display: flex;
  justify-content: center;
  margin-right: 0.75rem;
  width: 2rem;
  height: 2rem;
  background: lightpink;
  border-radius: 1rem;
  font-size: 0.875rem;
  line-height: 2rem;
  font-weight: 500;
}
.memberTileContainer .memberTile .tileHeader .firstNameLetterBubbleActive, .memberTileContainer .currentMemberTile .tileHeader .firstNameLetterBubbleActive {
  background: linear-gradient(225deg, #25a584 0%, #00a9a5 100%);
  border-color: #2ecfa5;
  color: #ffffff;
}
.memberTileContainer .memberTile .tileHeader .firstNameLetterBubbleActive .firstName, .memberTileContainer .currentMemberTile .tileHeader .firstNameLetterBubbleActive .firstName {
  color: #25a584;
}
.memberTileContainer .memberTile .tileHeader .memberName, .memberTileContainer .currentMemberTile .tileHeader .memberName {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.memberTileContainer .memberTile .tileHeader .memberName p, .memberTileContainer .currentMemberTile .tileHeader .memberName p {
  margin-bottom: 0;
}
.memberTileContainer .memberTile .tileHeader .memberName .you, .memberTileContainer .currentMemberTile .tileHeader .memberName .you {
  margin-bottom: 0;
  font-size: 0.875rem;
  color: rgba(65, 68, 79, 0.4);
}
.memberTileContainer .memberTile .tileHeader .currentMember p, .memberTileContainer .currentMemberTile .tileHeader .currentMember p {
  margin-bottom: 0;
}
.memberTileContainer .memberTile .tileHeader .currentMember .current, .memberTileContainer .currentMemberTile .tileHeader .currentMember .current {
  color: #41444f;
  font-size: 0.75rem;
  line-height: 1rem;
  opacity: 0.4;
}
.memberTileContainer .memberTile .infoBox, .memberTileContainer .currentMemberTile .infoBox {
  display: flex;
  justify-content: flex-start;
}
.memberTileContainer .memberTile .balanceDue, .memberTileContainer .currentMemberTile .balanceDue {
  display: flex;
  justify-content: flex-start;
  font-size: x-large;
  font-weight: 500;
}
.memberTileContainer .memberTile .balanceDetails, .memberTileContainer .currentMemberTile .balanceDetails {
  display: flex;
  justify-content: flex-start;
  font-size: small;
}
.memberTileContainer .currentMemberTile {
  box-shadow: 0px 0px 0px 2px #25a584;
}

@media (min-width: 0px) and (max-width: 769px) {
  .memberTile, .memberTileContainer .currentMemberTile {
    width: 15rem;
  }
  .currentMemberTile {
    width: 100%;
    box-shadow: 0px 0px 0px 2px #25a584;
  }
}
@media (min-width: 770px) {
  .memberTile, .memberTileContainer .currentMemberTile {
    width: 16rem;
  }
}
@media (min-width: 1025px) {
  .memberTile, .memberTileContainer .currentMemberTile {
    width: 16rem;
  }
}
@media (min-width: 1200px) {
  .memberTile, .memberTileContainer .currentMemberTile {
    width: 18rem;
  }
}
